.NavBar {
    z-index: 30;
    padding: 0.8rem;

    .NavBar-inner {
        padding: 0.5rem;
        background-color: rgb(255, 255, 255, 0.2);
    
        .NavBar-companyLogo {
            img {
                width: 5rem;
            }
        }

        .NavBar-navMenu {
            /*position: relative;*/
            position: absolute;
        }
    }
}
